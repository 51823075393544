<template>
  <div class="template-container">
    <div class="top-box clear">
      <div class="grid-content">
        <span class="label">产品编号：</span><span class="info">{{ baseInfo.proId||'-' }}</span>
      </div>
      <div class="grid-content">
        <span class="label">产品类别：</span><span class="info">{{ $code('proCate',baseInfo.proCate) }}</span>
      </div>
      <div class="grid-content">
        <span class="label">产品品种：</span><span class="info">{{ $childrenCode('proCate',baseInfo.proCate,baseInfo.proType) }}</span>
      </div>

      <div class="grid-content">
        <span class="label">产品名称：</span><span class="info">{{ baseInfo.proName||'-' }}</span>
      </div>
      <div class="grid-content">
        <span class="label">核心企业：</span><span class="info">{{ baseInfo.coreEpName || '-' }}</span>
      </div>
      <div class="grid-content">
        <span class="label">资金方：</span><span class="info">{{ baseInfo.proFundName||'-' }}</span>
      </div>

      <div class="grid-content">
        <span class="label">集团模式：</span><span class="info">{{ baseInfo.groupCmpName?'是':'否' }}</span>
      </div>
      <div v-if="baseInfo.groupCmpName" class="grid-content">
        <span class="label">所属集团：</span><span class="info">{{ baseInfo.groupCmpName }}</span>
      </div>

      <div class="grid-content">
        <span class="label">资金方产品编号：</span><span class="info">{{ baseInfo.proFundNo || '-' }}</span>
      </div>
      <div class="grid-content">
        <span class="label">资方业务主体编号：</span><span class="info">{{ baseInfo.proFundMainNo || '-' }}</span>
      </div>
      <div class="grid-content">
        <span class="label">版本号：</span><span class="info">{{ $codeNew(bankVersionList,baseInfo.bankVersion) }}</span>
      </div>
      <div class="grid-content">
        <span class="label">付息方式：</span><span class="info">{{ $codeNew(intersTypeList,baseInfo.interstType + '') }}</span>
      </div>
      <div class="grid-content">
        <span class="label">授信来源：</span><span class="info">{{ $code('proFundRes',baseInfo.proFundRes) }}</span>
      </div>

      <div v-if="baseInfo.proCate!='04'" class="grid-content">
        <span class="label">明暗标识：</span><span class="info">{{ $code('proMaFlag',baseInfo.proMaflag) }}</span>
      </div>
      <div v-if="baseInfo.proCate!='04'" class="grid-content">
        <span class="label">有无追索权：</span><span class="info">{{ $code('proZsFlag',baseInfo.proZsflag) }}</span>
      </div>
      <div v-if="baseInfo.proCate!='04'" class="grid-content">
        <span class="label">融资单据类型：</span><span class="info">{{ $code('billType',baseInfo.proBillType) }}</span>
      </div>

      <div v-if="baseInfo.proCate!='04'" class="grid-content">
        <span class="label">融资期限范围：</span><span class="info">{{ baseInfo.fundRangeMin }}-{{ baseInfo.fundRangeMax }}天</span>
      </div>
      <div v-if="baseInfo.proCate!='04'" class="grid-content">
        <span class="label">额度最晚使用期(天)：</span><span class="info">{{ baseInfo.proCreditLastDate||'-' }}</span>
      </div>
      <div v-if="baseInfo.proCate!='04'" class="grid-content">
        <span class="label">是否允许提前还款：</span><span class="info">{{ $code('proPrepaymentFlag',baseInfo.proPrepaymentFlag) }}</span>
      </div>

      <!--          <div class="grid-content" v-if="baseInfo.proCate!='04'">-->
      <!--            <span class="label">q：</span><span class="info">{{$code('proGraceFlag',baseInfo.proGraceFlag)}}</span>-->
      <!--          </div>-->
      <!--          <div class="grid-content" v-if="baseInfo.proCate!='04'">-->
      <!--            <span class="label">宽限期限(天)：</span><span class="info">{{baseInfo.proGraceTerm||'-'}}</span>-->
      <!--          </div>-->
      <div class="grid-content">
        <span class="label">单笔融资限额：：</span><span class="info">{{ baseInfo.singleFinanceAmountMin }}-{{ baseInfo.singleFinanceAmount }}元</span>
      </div>

      <div v-if="baseInfo.proCate!='04'" class="grid-content">
        <span class="label">还款方式：</span><span class="info">{{ $codeNew(RETUKIND,baseInfo.proPaymentWay) }}</span>
      </div>
      <!--          <div class="grid-content" v-if="baseInfo.proCate!='04'">-->
      <!--            <span class="label">应收账款转让通知书：</span><span class="info">{{compactList.agree2?compactList.agree2.compactName:'无'}}</span>-->
      <!--          </div>-->
      <!--          <div class="grid-content" v-if="baseInfo.proCate!='04'">-->
      <!--            <span class="label">融资协议：</span><span class="info">{{compactList.agree3?compactList.agree3.compactName:'无'}}</span>-->
      <!--          </div>-->
      <template v-if="baseInfo.proCate!='04'">
        <div v-for="(item,index) in fileNameList" :key="index" class="grid-content">
          <span class="label">{{ item.dictName }}：</span><span class="info">{{ compactList['agree'+item.dictId].compactName }}</span>
        </div>
      </template>
      <div v-if="baseInfo.proCate!='04'" class="grid-content">
        <span class="label">是否支持多级：</span><span class="info">{{ baseInfo.proEchainFlag?'是':'否' }}</span>
      </div>
      <div v-if="baseInfo.proCate!='04' && baseInfo.proEchainFlag==1" class="grid-content">
        <span class="label">多级融资协议：</span><span class="info">{{ compactList.agree5?compactList.agree5.compactName:'无' }}</span>
      </div>
      <!--          <div class="grid-content" v-if="baseInfo.proCate!='04'">-->
      <!--            <span class="label">应收账款转让协议：</span><span class="info">{{compactList.agree1?compactList.agree1.compactName:'无'}}</span>-->
      <!--          </div>-->
      <!--          <div class="grid-content">-->
      <!--            <span class="label">法律文本：</span><span class="info">{{compactList.agree4?compactList.agree4.compactName:'无'}}</span>-->
      <!--          </div>-->
    </div>

    <div v-if="baseInfo.proCate!='04'" class="parameTitle">
      <span class="leftLine">&nbsp;</span>
      <span class="rightText">费用-资金方</span>
    </div>
    <div class="subtitle">
      <span class="label">费用配置方式：</span><span class="info">{{ baseInfo.fundConfigType==0?'从接口读取':'平台配置' }}</span>
    </div>
    <el-table v-if="baseInfo.proCate!='04' && baseInfo.fundConfigType==1" :border="true" :data="costCapitalSideList" class="br-table">
      <el-table-column label="费用名称" prop="costName" />
      <el-table-column label="收取时点" prop="collectTime" />
      <el-table-column label="名称定义" prop="nameDefinition" width="250" />
      <el-table-column label="收取方式(年化率)">
        <template slot-scope="scope">
          <span v-if="scope.row.numflag">{{ scope.row.costnum }}{{ $code('collectTypeTwo',scope.row.collectType) }}</span>
          <span v-if="scope.row.costName=='保理费'">{{ $code('factoringType',scope.row.factoringType) }}</span>
          <span v-if="scope.row.costName=='融资利息'">是否前置收取：{{ $code('preCharge',scope.row.preCharge) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="baseInfo.proCate!='04'" class="parameTitle">
      <span class="leftLine">&nbsp;</span>
      <span class="rightText">费用-平台</span>
    </div>
    <el-table v-if="baseInfo.proCate!='04'" :border="true" :data="detailData" class="br-table">
      <el-table-column label="费用名称" prop="costType">
        <template slot-scope="scope">
          {{ $codeNew(prodCostTypePlat,scope.row.costType) }}
        </template>
      </el-table-column>
      <el-table-column label="收取时点" prop="costGentime">
        <template slot-scope="scope">
          {{ $code("collectTime",scope.row.costGentime) }}
        </template>
      </el-table-column>

      <!--          <el-table-column label="名称定义" prop="costRemark" width="250"></el-table-column>-->
      <el-table-column label="收取方式(年化率)">
        <template slot-scope="scope">
          {{ scope.row.costNum }}{{ $code('collectTypeTwo',scope.row.costUnit) }}
          <div v-if="scope.row.costUnit!='01'" style="margin-top: 10px">
            最低{{ scope.row.minimum }}元/笔
          </div>
        </template>
      </el-table-column>
      <el-table-column label="支付方" prop="costPayer">
        <template slot-scope="scope">
          {{ $codeNew(payer,scope.row.costPayer) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    detailData: {
      type: Array,
      default: () => ([])
    },
    proInfo: {
      type: Object,
      default: () => ({})
    },
    compacts: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      costPlatformList: [],
      costCapitalSideList: [],
      fileNameList: [],
      compactList: {}
    }
  },
  computed: {
    coreEnterPriseList() {
      return this.$store.state.tradeFinancing.coreEnterPriseList
    },
    pushBankTypeList() {
      return this.$store.getters.getDictionaryItem('PUSH_BANK_TYPE')
    },
    intersTypeList() {
      return this.$store.getters.getDictionaryItem('INTERS_TYPE')
    },
    bankVersionList() {
      return this.$store.getters.getDictionaryItem('BANK_VERSION')
    },
    RETUKIND() {
      return this.$store.getters.getDictionaryItem('RETUKIND')
    },
    payer() {
      return this.$store.getters.getDictionaryItem('PAYER')
    },
    prodCostTypePlat() {
      return this.$store.getters.getDictionaryItem('PROD_COST_TYPE_PLAT')
    }
  },
  watch: {
    proInfo: {
      handler(newV) {
        if (!newV) return
        this.baseInfo = newV && JSON.parse(JSON.stringify(newV))
        this.costCapitalSideList = [
          {
            costName: '逾期费',
            collectTime: '逾期时收取',
            nameDefinition: '未按期还款的处罚金',
            collectType: this.baseInfo['proCosta'] && this.baseInfo['proCosta'].split(',')[1],
            costnum: this.baseInfo['proCosta'] && this.baseInfo['proCosta'].split(',')[0],
            numflag: true
          },
          {
            costName: '提前还款手续费',
            collectTime: '提前还款时收取',
            nameDefinition: '违反合同约定的处罚金',
            collectType: this.baseInfo['proCostb'] && this.baseInfo['proCostb'].split(',')[1],
            costnum: this.baseInfo['proCostb'] && this.baseInfo['proCostb'].split(',')[0],
            numflag: true
          },
          {
            costName: '宽限期手续费',
            collectTime: '展期时收取',
            nameDefinition: '宽限期（展期）时向融资方收取的费用',
            collectType: this.baseInfo['proCostd'] && this.baseInfo['proCostd'].split(',')[1],
            costnum: this.baseInfo['proCostd'] && this.baseInfo['proCostd'].split(',')[0],
            numflag: true
          },
          {
            costName: '催收费',
            collectTime: '催收时收取',
            nameDefinition: '产生逾期后催款费用（委托催收、司法诉讼）',
            collectType: this.baseInfo['proCoste'] && this.baseInfo['proCoste'].split(',')[1],
            costnum: this.baseInfo['proCoste'] && this.baseInfo['proCoste'].split(',')[0],
            numflag: true
          },
          {
            costName: '保理费',
            collectTime: '放款时收取',
            nameDefinition: '保理费',
            collectType: '00',
            costnum: '0',
            numflag: false,
            factoringType: this.baseInfo['proCostf']
          },
          {
            costName: '融资利息',
            collectTime: '放款/还款时收取',
            nameDefinition: '融资利息',
            collectType: '00',
            costnum: '0',
            numflag: false,
            preCharge: this.baseInfo['preCharge']
          }
        ]
      },
      immediate: true,
      deep: true
    },
    compacts: {
      handler(newV) {
        if (!newV) return
        newV.forEach((item, val) => {
          this.fileNameList.push({
            dictName: item.fileName,
            dictId: item.compactType
          })
          this.compactList['agree' + item.compactType] = item
          if (item.compactType === '10') {
            this.compactList['agree5'] = item
          }
        })
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
<style scoped lang="scss">
.template-container .template-content {
  margin-top: 0;
}
.parameTitle {
  height: 20px;
  line-height: 20px;
  margin: 15px 0;
}
.leftLine {
  width: 3px;
  height: 20px;
  display: inline-block;
  background: #4da1ff;
}
.rightText {
  font-size: 14px;
  color: #333;
  padding-left: 12px;
}
.top-box{
  .grid-content{
    float: left;
  }
}
.grid-content {
  font-size: 13px;
  display: flex;
  align-items: center;
  line-height: 30px;
  width: 31%;
  padding-right: 4px;

  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items:baseline;
  -webkit-align-items: baseline;

  & > label {
    white-space: nowrap;
    min-width: 130px;
    text-align: right;
  }
  .label{
    margin-right: 4px;
  }
  .info{
    -webkit-flex:1;
    -moz-flex:1;
    -ms-flex:1;
    -o-flex:1;
    -khtml-flex:1;
    flex:1;

    color: #606266;
  }
}
.subtitle{
  font-size: 13px;
margin-bottom: 10px;
  .label{

  }
  .info{
    color: #606266;
  }
}

.content-right {
  display: flex;
  justify-content: flex-end;
}
.br-input-full {
  flex: 1;
}
.br-input-small {
  width: 58px;
  //   margin: 0 5px 0 0;
  /deep/input.el-input__inner {
    text-align: center;
    padding: 0;
  }
}
.br-select.br-select-medium {
  width: 80px !important;
  margin: 0 8px !important;
}
.br-right-label {
  font-size: 12px;
  margin: 0 5px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.br-select {
  /deep/.el-input__icon {
    line-height: 0;
  }
}
.br-table {
  /deep/.el-input__icon {
    line-height: 0;
  }
  .br-select {
    width: 160px;
  }
}
</style>
