<template>
  <div class="page-card-demo  page-info-content">
    <el-form ref="Forms" :model="formParams" size="small" label-width="130px" :rules="rules" @submit.native.prevent>
      <el-form-item label="审核结果：" label-width="130px" class="br-form-item-label" prop="approvalResult">
        <el-radio-group v-model="formParams.approvalResult" class="br-radio">
          <el-radio v-for="(item,index) in handleAuthRadio(resourceByTaskList)" :key="index" :label="item.VARIABLE_CODE">
            {{ item.VARIABLE_NAME }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="审核意见：" label-width="130px" class="br-form-item-label" prop="approvalSuggestion">
        <el-input v-model="formParams.approvalSuggestion" type="textarea" class="br-input" :autosize="{ minRows: 2, maxRows: 6}" />
      </el-form-item>
    </el-form>
    <div class="button-group">
      <el-button class="br-btn-large br-cancel-btn" size="small" @click="handleCancel">
        取消
      </el-button>
      <el-button type="primary" class="br-btn-large br-submit-btn" size="small" :loading="btnloading" @click="handleSubmit">
        提交
      </el-button>
    </div>
  </div>
</template>
<script>
import { saveApprove, getResourceByTask } from '@/api/tradeFinancing.js'
export default {
  data() {
    return {
      btnloading: false,
      resourceByTaskList: [], // 审核结果按钮(通过，拒绝，回退)
      formParams: {
        approvalResult: 'approve_pass'
      },
      rules: {
        approvalResult: [
          { required: true, message: '请选择审核结果', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      getResourceByTask({
        activiteId: this.$route.query.activiteId,
        currentNodeId: this.$route.query.currentNodeId
      }, res => {
        if (res.code === 200) {
          this.resourceByTaskList = res.data
        }
      })
    },
    handleCancel() {
      this.$router.go(-1)
    },
    handleAuthRadio(radioList) {
      var auth = []
      for (var i in radioList) {
        if (radioList[i].VARIABLE_TYPE === '2') {
          auth.push(radioList[i])
        }
      }
      return auth
    },
    // 提交
    handleSubmit() {
      this.$refs['Forms'].validate(valid => {
        if (valid) {
          this.btnloading = true
          const params = {
            proId: this.$route.query.proId,
            proCate: this.$route.query.proCate,
            proType: this.$route.query.proType,
            proFundId: this.$route.query.proFundId,
            proCheckFlag: this.$route.query.proCheckFlag,
            approveResult: this.formParams['approvalResult'], // 审批结果
            approveAdvise: this.formParams['approvalSuggestion'], // 审批意见
            nodeId: this.formParams['backResult'], // 回退至节点  的id
            reason: '',
            activiteId: this.$route.query.activiteId,
            applyCode: this.$route.query.id, // 之前proId
            currentNodeId: this.$route.query.currentNodeId
          }
          saveApprove(params, res => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '提交成功'
              })
              this.$router.push('/productManage/indexItem')
            }
            this.btnloading = false
          })
        } else {
          this.btnloading = false
          return false
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.template-container .template-content {
  margin-top: 0;
}
.br-input {
  margin-right: 10px;
}
.button-group {
  text-align: center;
}
</style>
