<template>
  <div class="page-card-demo  page-info-content">
    <el-table :border="true" :data="auditHistoryList" class="br-table" style="width: 80%">
      <el-table-column label="申请编号" prop="applyCode" />
      <el-table-column label="当前节点" prop="nodeName" />
      <el-table-column label="审核人" prop="approveName" />
      <el-table-column label="审核意见" prop="approveResult">
        <template slot-scope="scope">
          {{ $code("approveResult",scope.row.approveResult) }}
        </template>
      </el-table-column>
      <el-table-column label="审核说明" prop="approveAdvise" />
      <el-table-column label="审核时间" prop="approveTime">
        <template slot-scope="scope">
          {{ scope.row.approveTime?moment(scope.row.approveTime).format("YYYY-MM-DD HH:mm:ss"):"" }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import moment from 'moment'
import { getAuditHistory } from '@/api/tradeFinancing.js'
export default {
  data() {
    return {
      auditHistoryList: [],
      moment
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      getAuditHistory({ applyCode: this.$route.query.id }, res => {
        this.auditHistoryList = res.data.list
      })
    }
  }
}
</script>
